import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import frFlag from '../assets/fr.svg';
import gbFlag from '../assets/gb.svg';
import deFlag from '../assets/de.svg';
import esFlag from '../assets/es.svg';
import ptFlag from '../assets/pt.svg';
import itFlag from '../assets/it.svg';

import './AppLayout.css';

const languageSwitchItems = [
  { code: 'fr', flag: frFlag },
  { code: 'en', flag: gbFlag },
  { code: 'de', flag: deFlag },
  { code: 'es', flag: esFlag },
  { code: 'pt', flag: ptFlag },
  { code: 'it', flag: itFlag },
];

function LanguageSwitch() {
  const { i18n } = useTranslation();

  const changeLang = (lang: string) => i18n.changeLanguage(lang);
  return (
    <div className="AppLayout-langSwitch">
      {languageSwitchItems.map(({ code, flag }) => (
        <img
            key={code}
          className="AppLayout-flag"
          src={flag}
          alt={`${code} flag`}
          onClick={() => changeLang(code)}
        />
      ))}
    </div>
  );
}

function AppLayout({
  children,
  titleElement,
}: {
  children?: ReactNode;
  titleElement?: ReactNode;
}) {
  return (
    <div>
      <header className="App-header container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <img
              className="App-logo"
              src="https://www.fondationeczema.org/themes/custom/fondation_eczema/assets/logos/logo-header.svg"
              alt="Logo Fondation Eczéma"
            />
            <div className="AppLayout-title">{titleElement}</div>
            <LanguageSwitch />
          </div>
        </div>
      </header>
      <main className="">{children}</main>
    </div>
  );
}

AppLayout.defaultProps = {
  children: null,
  titleElement: null,
};

export default AppLayout;
