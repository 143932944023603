import React, { Children, useState } from 'react';
import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';

import './Accordion.css'

const Accordion = ({ title, children, icon }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="Accordion">
      <div className="label" onClick={() => setIsActive(!isActive)}>
        <div className='icon-title'>
          <div className='icon'>{icon}</div>
          <div className="accordion-title" >{title}</div>
        </div>
        <div >
          <Chevron className={`${isActive ? "chevron-up" : "chevron-down"}`} />
        </div>
      </div>
      {isActive && <div className="content">{children}</div>}
    </div>
  );
};

export default Accordion;
