import axios from 'axios';
import { apiOrigin } from '../settings';

const instance = axios.create({
  baseURL: `${apiOrigin}/api`,
  withCredentials: true,
});

export async function getDrugs() {
  return instance.get('/drugs').then(({ data }) => data);
}

export async function getActionPlan(actionPlanId: string) {
  return instance.get(`/action-plans/${actionPlanId}`).then(({ data }) => data);
}

export async function getActionPlanPDF(actionPlanId: string, filename: string) {
  return instance
    .get(`/action-plans/${actionPlanId}/${filename}`)
    .then(({ data }) => data);
}

export async function saveActionPlan(actionPlanId: string, payload: any) {
  return instance
    .put(`/action-plans/${actionPlanId}`, payload)
    .then(({ data }) => data);
}

export async function verifyEmail(token: string) {
  return instance.post('/confirm-email', { token }).then(({ data }) => data);
}
