import { CSSProperties } from 'react';
import './Spinner.css';

function Spinner({
  extraClass,
  style,
}: {
  extraClass?: string;
  style?: CSSProperties;
}) {
  return (
    <div style={{ position: 'relative', ...style }}>
      <div className={`spinner ${extraClass}`} />
    </div>
  );
}

Spinner.defaultProps = {
  extraClass: '',
  style: {},
};

export default Spinner;
