import { IDrug, IDrugOption, TFunction } from '../types';

function formatLabel(drug: IDrug, t: TFunction) {
  const { name, concentration, variant = '' } = drug;
  let label = t(`drugs.${name}`).toUpperCase();
  if (concentration) {
    label += ` - ${concentration}%`;
  }
  if (variant) {
    label += ` (${t(`drugs.${variant}`)})`;
  }
  return label;
}

export default function formatDrugs(
  drugs: IDrug[],
  t: TFunction
): IDrugOption[] {
  return drugs.map((d) => ({
    category: d.category,
    value: d._id,
    label: formatLabel(d, t),
  }));
}
