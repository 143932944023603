import { useTranslation } from 'react-i18next';

import AppFallbackLayout from '../components/AppFallbackLayout';

export default function ResourceExpired() {
  const { t } = useTranslation();
  return (
    <AppFallbackLayout>
      <h1>{t('resourceExpired.title')}</h1>
      <p>{t('resourceExpired.details')}</p>
    </AppFallbackLayout>
  );
}
