import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  pfidClientID,
  pfidConnectionUrl,
  pfidCreateAccountUrl,
  pfidGetTokenUrl,
} from '../../settings';
import AppLayout from '../../layouts/AppLayout';
import './Auth.css';

// utils
function base64URLEncode(str) {
  return btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}
function bufferToString(buffer) {
  const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = [];
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join('');
}

// generate the code_verifier
function generateCodeVerifier() {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return base64URLEncode(bufferToString(array));
}

// generate the code_challenge
async function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const buffer = await window.crypto.subtle.digest('SHA-256', data);
  const bufferString = String.fromCharCode.apply(null, new Uint8Array(buffer));
  return base64URLEncode(bufferString);
}

function setCodeVerifier(code) {
  localStorage.setItem('codeVerifier', code);
}
function getCodeVerifier() {
  return localStorage.getItem('codeVerifier');
}

const Auth = ({authorize = false}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [codeChallenge, setCodeChallenge] = useState(null);

  const code = new URLSearchParams(location.search).get('code');
  const state = new URLSearchParams(location.search).get('redirectUri') || '';
  const redirectUri = new URLSearchParams(location.search).get('state') || '';
  const domain = `${window.location.origin}/auth/authorize`;

  useEffect(() => {
    if (authorize) {
      const codeVerifier = getCodeVerifier();
      if (code && codeVerifier) {
        fetch(
          `${pfidGetTokenUrl}?grant_type=authorization_code&code=${code}&redirect_uri=${encodeURIComponent(domain)}&client_id=${pfidClientID}&code_verifier=${codeVerifier}`,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
          .then((response) => (response.json()))
          .then((response) => {
            if (document.domain === 'localhost') {
              document.cookie = `jwt_pfid=${encodeURI(response.access_token)};path=/;`;
            } else {
              document.cookie = `jwt_pfid=${encodeURI(response.access_token)};domain=.${document.domain};path=/;`;
            }
            window.location.assign(redirectUri);
          });
      }
    }
  }, [code]);

  useEffect(() => {
    if (!authorize) {
      const codeVerifier = generateCodeVerifier();
      setCodeVerifier(codeVerifier);
      generateCodeChallenge(codeVerifier).then((codeGenerated) => setCodeChallenge(codeGenerated));
    }
  }, []);

  return (
    <AppLayout titleElement={<h1>{t('auth.headTitle')}</h1>}>
      <div className="Auth">
        <div className="AuthContent">
          <div className="AuthImage">
            <img
              src="https://pfid.pierre-fabre.dev/logo.svg"
              alt="Pierre Fabre ID Logo"
            />
          </div>
          <div className="AuthRightSide">
            <div className="AuthRightSideContent">
              <img
                className="AuthLogo"
                src={`${process.env.PUBLIC_URL}/logos/eczema-care-plus-logo.png`}
                alt="Eczema Care Plus Logo"
              />
              <h1>{t('auth.title')}</h1>
              <p className="AuthP">{t('auth.explanations1')}</p>
              <p className="AuthP">{t('auth.explanations2')}</p>
              <button
                className="AuthButton"
                onClick={() => window.location.assign(pfidCreateAccountUrl)}
              >
                {t('auth.registerButton')}
              </button>
              <button
                className="AuthButton AuthButtonSecondary"
                onClick={() => {
                  if (codeChallenge) {
                    window.location.assign(
                      `${pfidConnectionUrl}?client_id=${pfidClientID}&response_type=code&redirect_uri=${encodeURIComponent(domain)}&code_challenge=${codeChallenge}&code_challenge_method=S256&theme=eczcp&scope=openid&state=${encodeURIComponent(state)}`
                    );
                  }
                }}
              >
                {t('auth.loginButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Auth;