import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getActionPlan, getDrugs } from '../helpers/api';
import formatDrugs from '../helpers/formatDrugs';
import { IActionPlan, IDrug, IDrugOption } from '../types';

interface IUseGetActionPlanRes {
  loading: boolean;
  error: Error | null;
  actionPlan: IActionPlan | null;
  drugOptions: IDrugOption[];
  setActionPlan: Dispatch<SetStateAction<IActionPlan | null>>;
}

export default function useGetActionPlan(
  actionPlanId: string | undefined
): IUseGetActionPlanRes {
  const [loading, setLoading] = useState(false);
  const [actionPlan, setActionPlan] = useState<IActionPlan | null>(null);
  const [drugs, setDrugs] = useState<IDrug[] | null>([]);
  const [error, setError] = useState<Error | null>(null);
  const { t, i18n } = useTranslation();

  const drugOptions = useMemo(
    () => formatDrugs(drugs, t),
    [drugs, i18n.language]
  );

  useEffect(() => {
    const fetchActionPlan = async () => {
      // This will never happen since we're behind a route with params
      // Only here to comply with TypeScript
      if (!actionPlanId) {
        return {
          loading: false,
          error: new Error('missing parameter'),
          actionPlan: null,
          drugOptions: [],
          setActionPlan: () => {
            /* */
          },
        };
      }

      try {
        setLoading(true);
        const [ap, _drugs] = await Promise.all([
          getActionPlan(actionPlanId),
          getDrugs(),
        ]);
        setActionPlan(ap);
        setDrugs(_drugs);
      } catch (err) {
        let errorMessage: string;
        if (!err?.response) {
          errorMessage = 'networkError';
        } else {
          const { status } = err.response;
          switch (status) {
            case 401:
              errorMessage = 'notAuthorized';
              break;
            case 403:
              errorMessage = 'notAccessibleAnymore';
              break;
            case 404:
              errorMessage = 'notFound';
              break;
            default:
              errorMessage = 'unexpected';
          }
        }
        const translatedMessage = t(`actionPlan.errors.${errorMessage}`);
        setError(new Error(translatedMessage));
      } finally {
        setLoading(false);
      }
    };

    fetchActionPlan();
  }, [actionPlanId]);

  return { loading, error, actionPlan, setActionPlan, drugOptions };
}
