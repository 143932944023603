import { ReactNode } from 'react';

import './AppFallbackLayout.css';

function AppFallbackLayout({ children = null }: { children?: ReactNode }) {
  return (
    <main className="AppFallbackLayout">
      <img
        className="App-logo"
        src="https://www.fondationeczema.org/themes/custom/fondation_eczema/assets/logos/logo-header.svg"
        alt="Logo Fondation Eczéma"
      />
      {children}
    </main>
  );
}

AppFallbackLayout.defaultProps = {
  children: null,
};

export default AppFallbackLayout;
