import React from 'react';
import Accordion from '../../components/Accordion/Accordion';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Dry } from '../../assets/icons/dry.svg';
import { ReactComponent as Lightning } from '../../assets/icons/lightning.svg';
import './Tab3.css';
import { useTranslation } from 'react-i18next';
const Tab3 = ({ data, onChange }: any) => {
  const { t } = useTranslation();
  return (
    <div className="Tab3">
      <h2 className="title">{t("actionPlan.sections.afterDays")}</h2>
      <div className="container-label">
        <Eye />
        <p>{t("actionPlan.watching")}</p>
      </div>
      <div className="table">
        <div className="col">
          <Accordion
            icon={<Dry />}
            title={t("actionPlan.faceAndBody")}
          >
            <div className="container">
              <p className="accordion-description">
                {t("actionPlan.sections.skinNotRedAnymore")}
              </p>
              <div className="accordion-cta-container">
                <Lightning />
                <p>{t("actionPlan.takingAction")}</p>
              </div>
              <div className="accordion-action-container">
                <p>{t("actionPlan.sections.stopAntiInflam")}</p>
                <label htmlFor="">
                  {t("actionPlan.sections.continueUsingEmollient")}
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder={t("actionPlan.treatmentName")}
                  value={data?.emollient?.details || ''}
                  onChange={(event) => onChange('followUpLow', 'emollient', 'details', event.target.value)}
                />
                <div className="time-per-day">
                  <input
                    type="number"
                    name=""
                    id=""
                    placeholder="X"
                    min={0}
                    value={data?.emollient?.dailyCount || 0}
                    onChange={(event) => onChange('followUpLow', 'emollient', 'dailyCount', event.target.value)}
                  />
                  <p>{t("actionPlan.timesDaily")}</p>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Tab3;
