import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { verifyEmail } from '../helpers/api';
import AppFallbackLayout from '../components/AppFallbackLayout';

export default function ConfirmEmail() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    verifyEmail(token)
      .then(() => setConfirmed(true))
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <AppFallbackLayout>
        <p>{t('confirmEmail.verificationPending')}</p>
      </AppFallbackLayout>
    );
  }
  if (confirmed) {
    return (
      <AppFallbackLayout>
        <h1>{t('confirmEmail.emailConfirmed')}</h1>
        <p>{t('confirmEmail.emailConfirmedPlus')}</p>
      </AppFallbackLayout>
    );
  }
  if (error) {
    return (
      <AppFallbackLayout>
        <h1>{t('global.error')}</h1>
        <p>{t('confirmEmail.couldNotConfirm')}</p>
      </AppFallbackLayout>
    );
  }
}
