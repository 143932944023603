import { useTranslation } from 'react-i18next';

import AppFallbackLayout from '../components/AppFallbackLayout';

export default function ActionPlanCompleted() {
  const { t } = useTranslation();
  return (
    <AppFallbackLayout>
      <h1>{t('savedAP.actionPlanSaved')}</h1>
      <p>{t('savedAP.details')}</p>
    </AppFallbackLayout>
  );
}
