import './Loader.css';

const Loader = ({size = '2em', thickness = '0.25em'}) => (
  <div
    style={{
      width: size || '2em',
      height: size || '2em',
      border: `${thickness || '0.25em'} solid #77C8D212`,
      animation: `spin 1.5s cubic-bezier(.53,.14,.38,.86) infinite`,
      borderLeftColor: '#77C8D2',
      borderRadius: '2em',
      margin: 'auto',
      boxSizing: 'border-box',
    }}
  />
);

export default Loader;