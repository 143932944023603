import { createContext, Dispatch, SetStateAction } from 'react';

import { IActionPlan, IDrugOption } from '../types';

interface IActionPlanContext {
  actionPlan: IActionPlan | null;
  setActionPlan: Dispatch<SetStateAction<IActionPlan | null>>;
  drugOptions: IDrugOption[] | null;
}

const ActionPlanContext = createContext<IActionPlanContext>({
  actionPlan: null,
  setActionPlan: () => {
    /* */
  },
  drugOptions: [],
});

export default ActionPlanContext;
