import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../components/Accordion/Accordion';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Dry } from '../../assets/icons/dry.svg';
import { ReactComponent as Face } from '../../assets/icons/bxs_face.svg';
import { ReactComponent as Lightning } from '../../assets/icons/lightning.svg';
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as Bandage } from '../../assets/icons/tabler_bandage.svg';
import './Tab1.css';

// @ts-ignore
const Tab1 = ({ data, onChange }: any) => {
  const { t } = useTranslation();
  return (
    <div className='Tab1'>
      <h2 className='title'>{t("actionPlan.sections.firstDays")}</h2>
      <div className='container-label'>
        <Eye />
        <p>{t('actionPlan.watching')}</p>
      </div>
      <div className="table">
        <div className="col-left">
          <Accordion
            icon={<Dry />}
            title={t("actionPlan.sections.onPlates")}
          >
            <Accordion
              icon={<Face />}
              title={t("actionPlan.sections.onFace")}
            >
              <div className="container">
                <p className="accordion-description">
                  {t('actionPlan.sections.skinRedOozes')}
                </p>
                <div className='accordion-cta-container'
                  style={{ margin: '0 !important' }}
                >
                  <Lightning />
                  <p>
                    {t("actionPlan.takingAction")}
                  </p>
                </div>
                <div className='accordion-action-container'>
                  <label htmlFor="">
                    {t('actionPlan.sections.applicationAntiInflammatoryBody')}
                  </label>
                  <input
                    type="text"
                    placeholder={t('actionPlan.treatmentName')}
                    value={data.firstDaysHigh?.antiInflammatoryFace?.details || ''}
                    onChange={(event) => {
                      onChange(
                        'firstDaysHigh',
                        'antiInflammatoryFace',
                        'details',
                        event.target.value
                      );
                      onChange(
                        'firstDaysHigh',
                        'antiInflammatoryBody',
                        'details',
                        event.target.value
                      );
                      onChange(
                        'followUpModerate',
                        'antiInflammatory',
                        'details',
                        event.target.value
                      );
                    }}
                  />
                  <div className="time-per-day">
                    <input
                      type="number"
                      placeholder="X"
                      value={data.firstDaysHigh?.antiInflammatoryFace?.dailyCount || 0}
                      onChange={(event) => {
                        onChange(
                          'firstDaysHigh',
                          'antiInflammatoryFace',
                          'dailyCount',
                          event.target.value
                        );
                        onChange(
                          'firstDaysHigh',
                          'antiInflammatoryBody',
                          'dailyCount',
                          event.target.value
                        );
                      }}
                      min={0}
                    />
                    <p>{t('actionPlan.timesDaily')}</p>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              icon={<Person />}
              title={t('actionPlan.sections.onBody')}
            >
              <div className="container">
                <p className="accordion-description">
                  {t('actionPlan.sections.skinRedOozes')}
                </p>
                <div
                  className="accordion-cta-container"
                  style={{ margin: '20px 0 20px 0' }}
                >
                  <Lightning />
                  <p>{t('actionPlan.takingAction')}</p>
                </div>
                <div className="accordion-action-container">
                  <label htmlFor="">
                    {t('actionPlan.sections.applicationAntiInflammatoryBody')}
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('actionPlan.treatmentName')}
                    value={data.firstDaysHigh?.antiInflammatoryBody?.details || ''}
                    onChange={(event) => onChange('firstDaysHigh', 'antiInflammatoryBody', 'details', event.target.value)}
                  />
                  <div className='time-per-day'>
                    <input
                      type="number"
                      name=""
                      id=""
                      placeholder='X'
                      min={0}
                      value={data.firstDaysHigh?.antiInflammatoryBody?.dailyCount || 0}
                      onChange={(event) => onChange('firstDaysHigh', 'antiInflammatoryBody', 'dailyCount', event.target.value)}
                    />
                    <p>{t('actionPlan.timesDaily')}</p>
                  </div>
                </div>
              </div>
            </Accordion>
          </Accordion>
        </div>
        <div className="col">
          <Accordion
            icon={<Bandage />}
            title={t("actionPlan.sections.outPlates")}
          >
            <ul>
              <li>
                <p>{t("actionPlan.sections.skinNotRed")}</p>
              </li>
              <li>
                <p>{t("actionPlan.sections.skinDry")}</p>
              </li>
            </ul>
            <div className='accordion-cta-container'>
              <Lightning />
              <p>
                {t("actionPlan.takingAction")}
              </p>
            </div>
            <div className='accordion-action-container'>
              <label htmlFor="">
                {t("actionPlan.sections.continueUsingEmollient")}
              </label>
              <input
                type="text"
                name=""
                id=""
                placeholder={t("actionPlan.treatmentName")}
                value={data.firstDaysLow?.emollient?.details || ''}
                onChange={(event) => {
                  onChange('firstDaysLow', 'emollient', 'details', event.target.value);
                  onChange('followUpModerate', 'emollient', 'details', event.target.value);
                  onChange('followUpLow', 'emollient', 'details', event.target.value);
                }}
              />
              <div className='time-per-day'>
                <input
                  type="number"
                  name=""
                  id=""
                  placeholder='X'
                  min={0}
                  value={data.firstDaysLow?.emollient?.dailyCount || 0}
                  onChange={(event) => {
                    onChange('firstDaysLow', 'emollient', 'dailyCount', event.target.value);
                    onChange('followUpModerate', 'emollient', 'dailyCount', event.target.value);
                    onChange('followUpLow', 'emollient', 'dailyCount', event.target.value);
                  }}
                />
                <p>{t("actionPlan.timesDaily")}</p>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Tab1;