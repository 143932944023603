import { useTranslation } from 'react-i18next';

import AppFallbackLayout from '../components/AppFallbackLayout';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <AppFallbackLayout>
      <h1>{t('notFound.title')}</h1>
      <p>{t('notFound.details')}</p>
    </AppFallbackLayout>
  );
}
