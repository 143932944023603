import { Routes, Route, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'flexboxgrid/css/flexboxgrid.min.css';

import useGetActionPlan from './hooks/useGetActionPlan';
import ActionPlanContext from './contexts/ActionPlanContext';
import AppLayout from './layouts/AppLayout';
import Spinner from './components/Spinner';
import ErrorAlert from './components/ErrorAlert';
import AppFallbackLayout from './components/AppFallbackLayout';
import NotFound from './pages/NotFound';
import ResourceExpired from './pages/ResourceExpired';
import ActionPlanCompleted from './pages/ActionPlanCompleted';
import ConfirmEmail from './pages/ConfirmEmail';
import './App.css';
import ActionPlanFormDev from './pages/ActionPlanFormDev';
import Auth from './pages/Auth/Auth';

function App(): JSX.Element {
  const { actionPlanId } = useParams();
  const { loading, error, actionPlan, drugOptions, setActionPlan } =
    useGetActionPlan(actionPlanId);
  const { t } = useTranslation();

  if (loading) {
    return (
      <AppLayout titleElement={<Spinner style={{ marginLeft: '3em' }} />} />
    );
  }
  if (error) {
    return (
      <AppLayout>
        <ErrorAlert error={error} />
      </AppLayout>
    );
  }
  return (
    <ActionPlanContext.Provider
      value={{
        actionPlan,
        setActionPlan,
        drugOptions,
      }}
    >
      <AppLayout titleElement={<h1>{t('actionPlan.appTitle')}</h1>}>
        {/* <InfoBanner /> */}
        <ActionPlanFormDev />
        {/*<ActionPlanForm />*/}
      </AppLayout>
    </ActionPlanContext.Provider>
  );
}

function AppRouting() {
  return (
    <Routes>
      <Route path="" element={<AppFallbackLayout />} />
      <Route path="auth/authorize" element={<Auth authorize={true}/>} />
      <Route path="auth" element={<Auth />} />
      <Route path="confirm-email/:token" element={<ConfirmEmail />} />
      <Route path="wap/:actionPlanId" element={<App />} />
      <Route path="resource-expired" element={<ResourceExpired />} />
      <Route path="wap-completed" element={<ActionPlanCompleted />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouting;
